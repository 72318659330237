/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * Validator: https://metatags.io/
 * Facebook: https://developers.facebook.com/tools/debug/
 * Twitter: https://cards-dev.twitter.com/validator
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function reorderReactHelmetNodes() {
    const reactHelmetNodesList = document.querySelectorAll('meta[data-react-helmet="true"]')
    const firstStyleNode = document.querySelector('head style')

    Array.from(reactHelmetNodesList, el => {
        const ch = document.head.removeChild(el)
        document.head.insertBefore(ch, firstStyleNode)

        return el
    })
}

function setPrerenderReady() {
    // set flag used by Netlify Prerender service
    if (typeof window !== 'undefined') {
        window.prerenderReady = true
    }
}

function useFixSocialMetaTags() {
    useEffect(() => {
        reorderReactHelmetNodes()
        setPrerenderReady()
    }, [])
}

function SEO({ description, lang, meta, title, url, image }) {
    useFixSocialMetaTags()

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        author
                        description
                        keywords
                        title
                    }
                }
            }
        `
    )

    const {
        author: metaAuthor,
        description: metaDescription,
        keywords: metaKeywords,
        title: metaTitle,
    } = site.siteMetadata

    const metaImage = typeof window !== 'undefined' ? `${window.location.origin}/video_thumbnail_placeholder.png` : ''

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            url={url}
            titleTemplate={`%s | ${metaTitle}`}
            meta={[
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `description`,
                    content: description || metaDescription,
                },
                {
                    name: 'keywords',
                    content: metaKeywords,
                },
                {
                    property: 'fb:app_id',
                    content: process.env.GATSBY_FACEBOOK_APP_ID,
                },
                {
                    property: `og:title`, //Facebook
                    content: title || metaTitle,
                },
                {
                    property: `og:description`, //Facebook
                    content: description || metaDescription,
                },
                {
                    property: `og:url`, //Facebook - canonical URL
                    content: url,
                },
                {
                    property: 'og:image', //Facebook
                    content: image || metaImage,
                },
                {
                    name: 'twitter:card', //Twitter - Large Image
                    content: `summary`,
                },
                {
                    name: `twitter:site`,
                    content: `@VideoElephant`,
                },
                {
                    name: `twitter:title`, //Twitter
                    content: title || metaTitle,
                },
                {
                    name: `twitter:description`, //Twitter
                    content: description || metaDescription,
                },
                {
                    name: `twitter:image`, //Twitter
                    content: image || metaImage,
                },
                {
                    name: `twitter:creator`,
                    content: metaAuthor,
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
