import styled from '@emotion/styled'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import React, { useEffect, useRef, useState } from 'react'
import DismissButton from './dismiss-btn'
import Grid from './grid'
import remocoClient from '../remocoClient'
import { getRelevantEntriesData, setGaScreenName } from '../utils'

const sendGaSearchEvent = searchTerm => {
    trackCustomEvent({
        category: 'Search',
        action: 'Query',
        label: searchTerm,
    })
}

const getSearchResults = async searchTerm => {
    if (searchTerm === '') {
        return { results: [], searchTerm }
    }

    const limit = 12
    const filter = 'includeSeriesAssets:1'
    const results = (await remocoClient.search(searchTerm, limit, 'library_events,series', filter)) || {}
    const { list: resultsList = [] } = results
    const relevantResultData = getRelevantEntriesData(resultsList)

    return { results: relevantResultData, searchTerm }
}

const Search = () => {
    const [searchResults, setSearchResults] = useState([])
    const latestSearch = useRef('')
    const [searchTerm, setSearchTerm] = useState('')
    const focusSearch = useRef(null)

    useEffect(() => {
        focusSearch.current.focus()
        setGaScreenName('Search')
    }, [])

    useEffect(() => {
        if (searchTerm.length > 0) {
            getSearchResults(searchTerm, sendGaSearchEvent)
                .then(({ results, searchTerm: sTerm }) => {
                    // only update the search results if the search term hasn't subsequently changed
                    if (latestSearch.current === sTerm) {
                        setSearchResults(results)
                        // send event with search term for completed searches only
                        sendGaSearchEvent(sTerm)
                    }
                })
                .catch(err => {
                    console.error('Caught error performing search:', err)
                })
        } else {
            setSearchResults([])
        }
    }, [latestSearch, searchTerm])

    const gridFix = searchResults.length < 5 ? 'grid-adjust' : ''

    return (
        <SearchContainer>
            <InputWrapper>
                <SearchInput
                    type="text"
                    placeholder="Search for channels and shows"
                    value={latestSearch.current}
                    ref={focusSearch}
                    onChange={e => {
                        latestSearch.current = e.target.value
                        setSearchTerm(e.target.value)
                    }}
                />
                {latestSearch.length > 0 ? (
                    <DismissButton
                        onClickHandler={e => {
                            latestSearch.current = ''
                            setSearchTerm('')
                            focusSearch.current.focus()
                        }}
                        imgAltTxt="clear search query"
                    />
                ) : null}
            </InputWrapper>
            <Grid data={searchResults} gridFix={gridFix} />
        </SearchContainer>
    )
}

const SearchContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background-color: #0e0e0e;

    .grid {
        margin: 28px 48px 64px 48px;
        justify-content: flex-start;
    }

    @media screen and (max-width: 672px) {
        .grid {
            margin: 28px 16px 48px 16px;
        }
    }
`

const InputWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #141414;
    height: 220px;

    @media screen and (max-width: 672px) {
        height: 108px;
    }
`

const SearchInput = styled.input`
    width: 100%;
    min-height: 44px;
    background: transparent;
    border: none;
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 44px;
    margin-bottom: 24px;
    margin-left: 48px;
    margin-right: 48px;

    &::placeholder {
        color: white;
        opacity: 0.5;
    }

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 672px) {
        min-height: 20px;
        font-size: 17px;
        letter-spacing: 0.1px;
        line-height: 20px;
        margin-bottom: 12px;
        margin-left: 16px;
        margin-right: 16px;
    }
`

export default Search
