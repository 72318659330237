import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Search from '../components/search'

const SearchPage = ({ location }) => {
    return (
        <Layout location={location}>
            <SEO title="Search" />
            <Search />
        </Layout>
    )
}

export default SearchPage
